import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

// Animation for sliding in from the left
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for sliding in from the right
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeInFromCenter = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const neonGlow = keyframes`
  from {
    text-shadow: 
      0 0 2px #fff, 
      0 0 4px #286fff,
      0 0 8px #286fff;
  }
  to {
    text-shadow: 
      0 0 3px #fff, 
      0 0 6px #286fff,
      0 0 12px #286fff;
  }
`;

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  justify-content: flex-start;
  img {
    max-width: 70%;
    height: auto;
  }
`;

export const Menu = styled("h5")`
  font-size: 2.5rem;
  font-weight: bold;
  color: #FFFFFF;
  font-family: 'Acumin Pro Bold', serif;
  margin: 0 0.5rem; // Added horizontal margin
  text-align: center;

  &:hover {
    color: rgb(40, 111, 255);
  }

  animation: ${fadeInFromCenter} 1s ease-out forwards;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }

  @media (max-width: 414px) {
    font-size: 1rem;
  }
`;

export const SocialLink = styled("a")`
  margin-right: 1rem;
  display: inline-block;
  opacity: 0; // Start with the icon invisible
  animation: ${slideInFromRight} 1s ease-out forwards; // Animation for slide-in
  transition: transform 0.3s ease-in-out; // Transition for the hover effect

  &:hover {
    transform: scale(1.1); // Enlarge the icon slightly on hover
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Burger = styled(MenuOutlined)`
  font-size: 22px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #286fff; // Blue on hover
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  color: #FFFFFF;

  &:hover,
  &:active,
  &:focus {
    color: #B8860B; // Golden color on hover, active, focus
  }
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const NotHidden = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #18216d;
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) wavy underline;
  }
`;

export const HeaderArea = styled.div`
  background-color: rgba(28, 28, 28, 0.4);
  padding: 0.5rem 0.5rem; // Reduced top and bottom padding
  border-radius: 12px;
  border: 1px solid #ccc;
  box-shadow: 
    0 0 4px #98b9fd,
    0 0 6px #286fff,
    0 0 12px #286fff;
  white-space: nowrap;
  font-size: 4vw;

  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInFromCenter} 1s ease-out forwards, ${neonGlow} 1.5s ease-in-out infinite alternate;

  @media only screen and (max-width: 768px) {
    font-size: 5vw;
  }

  @media only screen and (max-width: 480px) {
    font-size: 6vw;
  }

  width: fit-content;
  max-width: 100%;
`;