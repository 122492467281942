import React, { useState } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
// import Container from "../../common/Container";
// import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  HeaderArea,
  Menu,
  // SocialLink,
  // Burger, // Commenting out for later use
  // Label, // Commenting out for later use
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(false);
  };

  // MenuItem component is kept in case you want to add functionality later
  const MenuItem = () => {
    // Function to handle scrolling to different sections of the page
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    // Placeholder for future menu items
    return (
      <>
        {/* <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Request an Invitation")}</Button>
          </Span>
        </CustomNavLinkSmall> */}
      </>
    );
  };

//   return (
//     <HeaderSection>
//       <Container>
//         <Row justify="center">
//           <LogoContainer to="/" aria-label="homepage">
//             <SvgIcon src="logo1.svg" width="300vw" height="auto" />
//           </LogoContainer>
//           <NotHidden>
//             <MenuItem />
//           </NotHidden>
//           <Burger onClick={showDrawer}>
//             <Outline />
//           </Burger>
//         </Row>
//         <Drawer closable={false} open={visible} onClose={onClose}>
//           <Col style={{ marginBottom: "2.5rem" }}>
//             <Label onClick={onClose}>
//               <Col span={12}>
//                 <Menu>Menu</Menu>
//               </Col>
//               <Col span={12}>
//                 <Outline />
//               </Col>
//             </Label>
//           </Col>
//           <MenuItem />
//         </Drawer>
//       </Container>
//     </HeaderSection>
//   );
// };

return (
  <HeaderSection>
    <Row justify="space-between" align="middle" style={{ display: 'flex' }}>
      <Col span={24}>
        <HeaderArea>
          <Menu style={{ textAlign: 'center', display: 'block', width: '100%' }}>
            {t("The Boss Media Network")}
          </Menu>
        </HeaderArea>
      </Col>
        <Col>
          {/* <SocialLink href="https://twitter.com" className="social-link">
            <SvgIcon src="twitterx.svg" width="25px" height="25px" />
          </SocialLink>
          <SocialLink href="https://discord.com" className="social-link">
            <SvgIcon src="discord.svg" width="25px" height="25px" />
          </SocialLink> */}
        </Col>
      </Row>
  </HeaderSection>
);
};

export default withTranslation()(Header);